<template lang="pug">
    div.container.mx-auto.p-2.mt-20.gap-10
        app-header(title='Link your Steam account to your Discord account' icon='link')

        div.flex.flex-wrap.gap-5.mt-8.w-full
            a.option(:href='steamAuthUrl' rel='no-referrer' :class="{ 'steam-complete': !!userInfo }")
                div.flex.gap-4.flex-wrap.items-center
                    icon(icon='fab steam')
                    h1.text-2xl Steam
                p.text-lg.mt-2 {{ !userInfo ? 'Click here to login with your Steam account.' : 'Your Steam account is logged in!' }}

            div.option(@click='getDiscordHandler()' :class="{ 'discord-complete': userInfo.discordId, 'option-disabled': !userInfo }")
                div.flex.flex-wrap.gap-4.items-center
                    icon(icon='fab discord')
                    h1.text-2xl {{ userInfo.discordId ? 'Unlink' : 'Link' }} Discord
                p.text-lg.mt-2 {{ !userInfo.discordId ? (!!userInfo ? 'Click here to link your Discord account.' : 'You must first login with your Steam account before you can link your Discord account.') : 'Click here to unlink your Discord.' }}

                p.text-lg.mt-4.text-gray-300(v-if='hasUnlinked') You have unlinked your Discord account.
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AppHeader from '../components/App/Header.vue';
import Icon from '../components/App/Icon.vue';
import Button from '../components/App/Button.vue';

import { LINK_DISCORD, UNLINK_DISCORD, LOGOUT_USER } from '../store/types/actions';

export default {
    components: {
        AppHeader, Icon, Button
    },
    data() {
        return {
            loading: false,
            hasUnlinked: false,
            steamAuthUrl: process.env.VUE_APP_AUTH_API + '/login?callbackUrl=' + process.env.VUE_APP_FRONTEND + '/link-accounts&errorUrl=' + process.env.VUE_APP_FRONTEND + '/link-accounts',
        }
    },
    computed: {
        ...mapGetters( 'user', [ 'userInfo' ]),
        getDiscordHandler() {
            return this.userInfo.discordId ? this.onUnlinkDiscord : this.onLinkDiscord;
        },
    },
    methods: {
        onLinkDiscord() {
            this.loading = true;
            this.linkDiscord();
        },
        onUnlinkDiscord() {
            this.hasUnlinked = true;
            this.unlinkDiscord();
        },
        ...mapActions( 'user', {
            linkDiscord: LINK_DISCORD,
            unlinkDiscord: UNLINK_DISCORD,
            logoutAction: LOGOUT_USER
        } ),
        logout() {
            this.logoutAction();
            this.$router.push('/');
        }
    }
}
</script>

<style lang='scss' scoped>
.option {
    @apply bg-dark-600 p-8 rounded
    text-white flex-grow hover:bg-dark-700 transition cursor-pointer;

    &.option-disabled {
        @apply bg-dark-400 cursor-auto hover:bg-dark-400 pointer-events-none;
    }

    &.steam-complete {
        @apply bg-dark-400 cursor-auto pointer-events-none;
    }
}

</style>